<template>
  <section>
    <section class="d-flex justify-center pt-5 pb-5 bg-custom elevation-0 header">
      <img
        height="80"
        src="../assets/rio-mare-logo.svg"
      >
    </section>
    <v-container class="overflow-h">

<!-- *************  Filiera tracciata    *************** -->
<section class="d-flex flex-column pa-5 mt-10">
  <h1 class="text-center title-custom">SCOPRI LA PROVENIENZA DEL TUO TONNO</h1>
  <p class="mt-3 ma-0">Ti sei mai chiesto da dove arriva il tonno che metti sulla tua tavola? Con Rio Mare è tutto chiaro, 
    perché puoi conoscere tutta la sua storia: la sua specie, l'area di pesca, il metodo di pesca utilizzato e il nome della 
    nave che l'ha pescato. Così il prodotto che arriva sulla tua tavola ha sempre un nome, un cognome e un indirizzo!
    <br> <br>
    Con questo servizio online, attraverso semplici e brevi step, potrai richiedere informazioni sull'origine del pesce utilizzato in qualsiasi confezione di tonno Rio Mare prodotta a partire dal 1° gennaio 2018.
    Ricorda che puoi tracciare il tuo prodotto solo se sei munito del codice presente sulla confezione.
    </p>
</section>

<!-- ***************  Select Product    **************** -->
<section class="pa-5 pt-2">
  <label for="">
    Seleziona la categoria di prodotto che stai scansionando:
  </label>
  <v-select
  class="mt-2"
      v-model="productType"
      :items="itemsType"
      :rules="rules"
      label="Seleziona la categoria di prodotto"
      item-text="name"
      item-value="id"
      solo
      clearable
    ></v-select>
    <label for=""  v-if="productType">
    Scegli la grammatura del prodotto che stai scansionando:
  </label>
  <v-select
  v-if="productType"
  class="mt-2"
      v-model="product"
      :items="items"
      :rules="rules"
      label="Seleziona prodotto"
      item-text="weight"
      item-value="product_id"
      solo
    ></v-select>
</section>


<!--  ******************   Button Footer    ********************* -->
<section class="pl-5 pr-5 button-footer d-flex align-end">
  <v-row>
  <v-col cols="12">
    <!-- <router-link to="/scan_code"> -->
    <v-btn
      block
      color="primary"
      elevation="0"
      large
      @click="next"
      :disabled="!product || !productType"
    >
    Scansiona Codice
    </v-btn>
    <!-- </router-link> -->
  </v-col>
  <v-col cols="12" style="padding: 0">
    <p class="text-center ma-0">
      <b>o</b>
    </p>
  </v-col>
  <v-col cols="12">
    <v-btn
      block
      color="primary"
      elevation="0"
      large
      :disabled="disabled"
      @click="insertManualData"
    >
    Inserisci manualmente
    </v-btn>

  </v-col>
</v-row>
</section>

</v-container>
  </section>
</template>

<script>
import axios from 'axios'

  export default {
    name: 'HomeComponent',
    data () {
      return {
        product: null,
        productType: null,
        products: [],
        productsOriginal: [],
        productTypes: [],
        rules: [
          v => !!v || 'Seleziona un prodotto',
        ]
      }
    },
    computed: {
      items () {
        return this.products
      },
      itemsType () {
        return this.productTypes
      },
      disabled () {
        if (!this.product || !this.productType) {
          return true
        } else {
          return false
        }
      }
    },
    watch: {
      productType (v) {
        const id = v
        this.products = this.productsOriginal.filter(v => {
          return v.productTypeId == id 
        })
      }
    },
    async mounted () {
      sessionStorage.removeItem('code')
      sessionStorage.removeItem('ItemDetails')
      try {
        const resType = await axios.get(process.env.VUE_APP_MIDDLEWARE + '/productsTypes')
        this.productTypes = resType.data
        const res = await axios.get(process.env.VUE_APP_MIDDLEWARE + '/products')
        this.productsOriginal = res.data
      } catch (e) {
        this.productTypes = []
        this.productsOriginal = []
        console.error(e)
      }
      
    },
    methods: {
        next () {
          this.$router.push('/scan_code/' + this.product)
        },
         insertManualData () {
          this.$router.push('/insert_code/' + this.product)
        }
      }
  }
</script>
<style scoped>
  a {
    text-decoration: none;
  }

  .v-application .primary {
    background-color: #0a215c !important;
    border-color: #0a215c !important;
}

.v-application a {
    color: #0a215c;
}

.v-application .primary--text {
    color: #0a215c !important;
    caret-color: #0a215c !important;
}

.container {
  max-height: 100vh;
  overflow: hidden !important;
}


.text-primary {
  color: #0a215c !important; 
}

.v-list-item__title {
  overflow: initial !important;
  text-overflow: clip !important;
  white-space: initial !important;
}

.button-footer {
  position: fixed; 
  bottom: 30px; 
  width: 100%; 
  left: 0;
}

.header {
  position: relative; left: 0px; top: 0; width: 100%;
  padding: 0px
}
.mt-30 {
  margin-top: 30%
}

.button-footer {
  position: relative; 
  margin-top: 1rem;
}

.overflow-h {
  overflow: scroll !important;
}

@media screen and (min-width: 580px) {
  body {
    padding-left: 20%;
    padding-right: 20%;
  }

  .button-footer {
  position: relative; 
}

.header {
  position: relative;
}

.mt-30 {
  margin-top: 10%
}

}
</style>
