<template>
  <home-component />
</template>

<script>
import HomeComponent from '../components/HomeComponent.vue'

  export default {
    title: 'Rio Mare',
    name: 'Home',

    components: {
      HomeComponent,
    },
  }
</script>
