<template>
    <section>
        <EmailSendedComponent />
    </section>
</template>

<script>
import EmailSendedComponent from '../components/EmailSended.vue'
export default {
    title: 'Rio Mare',
    name: 'Email',
    components: {
        EmailSendedComponent
    }
}
</script>

<style>

</style>