import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import ScanCode from '../views/ScanCode.vue'
import InsertCode from '../views/InsertCode.vue'
// import CustomizePicture from '../views/CustomizePicture.vue'
import TraceabilityResult from '../views/TraceabilityResult.vue'
import Email from '../views/Email.vue'
import EmailSended from '../views/EmailSended.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/scan_code/:idProduct',
    name: 'ScanCode',
    component: ScanCode
  },
  {
    path: '/insert_code/:idProduct',
    name: 'InsertCode',
    component: InsertCode
  },
  {
    path: '/traceability_result/:idProduct',
    name: 'TraceabilityResult',
    component: TraceabilityResult
  },
  {
    path: '/email',
    name: 'Email',
    component: Email
  },
  {
    path: '/emailSended',
    name: 'Email Sended',
    component: EmailSended
  }
]

const router = new VueRouter({
  routes
})

export default router
