<template>
    <section>
        <EmailComponent />
    </section>
</template>

<script>
import EmailComponent from '../components/EmailComponent.vue'
export default {
    title: 'Rio Mare',
    name: 'Email',
    components: {
        EmailComponent
    }
}
</script>

<style>

</style>