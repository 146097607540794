<template>
  <section>
       <!-- *********** Logo ************** -->
       <Navbar position="email"></Navbar>

      <section v-if="loaded" class="pr-5 pl-5 mt-15 pt-5">
        <h1>
          Corrispondenza non trovata!
        </h1>
          <p class="mt-5">
            Ci dispiace! Al momento non abbiamo trovato le informazioni sul tuo tonno. Inserisci qui sotto la tua mail. Entro 48h 
            riceverai le informazioni che hai richiesto.
          </p>
          <p><b>Inserisci qui la tua mail:</b></p>
          <v-text-field
            v-model="email"
            label="Email"
            :rules="rules"
            hide-details="auto"
          ></v-text-field>

          <section class="mt-10">
            <p>
              INFORMATIVA PRIVACY DEDICATA AL SERVIZIO "TRACCIABILITÁ - SCOPRI LA PROVENIENZA DEL TUO TONNO"
            </p>
              <v-textarea
              no-resize
              rows="3"
              :value="disclaimer"
              readonly
              ></v-textarea>
               <v-checkbox
                v-model="checkbox"
                label="Acconsento al trattamento dei dati personali"
                ></v-checkbox>
                 <v-row class="pl-5 pr-5 button-footer mt-md-10">
                  <v-col cols="12">
                    <v-btn
                      block
                      color="primary"
                      elevation="0"
                      large
                      :disabled="!checkbox || !email"
                      @click="sendEmail"
                    >
                    Invia
                    </v-btn>
                  </v-col>
                </v-row>
          </section>
    </section>
    <section v-if="!loaded" style="height: 75vh" class="d-flex justify-center align-center">
      <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </section>
  </section>
</template>

<script>
import axios from 'axios'
import Navbar from "./NavbarComponent.vue"
export default {
  components: {
      Navbar: Navbar
    },
   data: () => ({
      checkbox: false,
      email: null,
      prod_res: null,
      loaded: false,
      note: null,
      itemDetais: null,
      code: null,
      rules: [
        value => !!value || 'Campo obbligatorio.',
        v => /.+@.+/.test(v) || 'Inserisci un indirizzo mail valido'
      ],
    }),
    computed: {
      disclaimer () {
        return 'Bolton Food S.p.A. con sede legale in Cermenate (CO), Via Luigi Einaudi 18/22, in qualità di Titolare del trattamento tratterà i Suoi dati personali (indirizzo e-mail) esclusivamente al fine di gestire le Sua richiesta di informazioni relative alla tracciabilità del tonno dei nostri prodotti. Il conferimento dei dati è necessario e il loro eventuale mancato conferimento comporta il mancato invio del form e, dunque, l’impossibilità di ricevere e soddisfare la Sua richiesta. Un parziale o errato rilascio dei dati, potrà comportare l’impossibilità di evadere pienamente o correttamente la Sua richiesta. Il trattamento dei dati in relazione alla compilazione del presente form ha come base giuridica il Suo consenso. I dati personali da Lei forniti saranno trattati con strumenti informatici nel rispetto di adeguate misure tecniche e organizzative di sicurezza idonee a prevenire la perdita dei dati, usi illeciti o non corretti e accessi non autorizzati. I Suoi dati verranno conservati per un periodo di 12 mesi dalla raccolta. Ai sensi del Capo III del GDPR, Lei ha sempre diritto a richiedere al Titolare l’accesso ai Suoi dati, la rettifica o la cancellazione degli stessi, la limitazione del trattamento o la possibilità di opporsi al trattamento, di richiedere la portabilità dei dati, di revocare il consenso al trattamento facendo valere questi e gli altri diritti previsti dal GDPR tramite semplice comunicazione al Titolare. Per richiedere informazioni sul trattamento dei dati od esercitare i diritti sui Suoi dati, La preghiamo di contattarci all’indirizzo privacy@boltonfood.com. È possibile contattare Il DPO inviando una e-mail all’indirizzo dpo@boltongroup.it.'
      },
    },
   async mounted () {
      
      this.code = sessionStorage.getItem('product')
      this.itemDetais = JSON.parse(sessionStorage.getItem('ItemDetails'))
      this.loaded = true
    },
    methods: {
     sendEmail() {
      this.loaded = false
        let emailObj = this.itemDetais
         emailObj.email = this.email
         emailObj.product_id = this.code
         axios.post(process.env.VUE_APP_MIDDLEWARE + '/sendEmail', {
          code: emailObj.code,
          description: emailObj.description,
          email: emailObj.email,
          lot: emailObj.lot,
          note: emailObj.note,
          product_id: emailObj.product_id * 1,
          production_hours: emailObj.production_hours,
          weight: emailObj.weight,
          weightId: emailObj.weightId * 1
          } )
         .then( () => {
          this.loaded = true
          this.$router.push('/emailSended')
         })
         .catch(err => {
          console.log(err)
          this.loaded = true
      })
    }
    }
}
</script>

<style scoped>
 .v-application .primary {
    background-color: #0a215c !important;
    border-color: #0a215c !important;
}

.v-application a {
    color: #0a215c;
}

.v-application .primary--text {
    color: #0a215c !important;
    caret-color: #0a215c !important;
}

.text-error {
  color: red;
}

.text-danger {
  color: orange;
}

.text-success {
  color: green;
}

a {
    text-decoration: none;
  }

h1 {
  font-size: 1.3rem;
  color: #0a215c;
}

</style>
