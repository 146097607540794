<template>
  <section>
    <Navbar position="picture"></Navbar>
    <v-container class="container">

<!-- *********** Header ************** -->

<!-- *************  CAM    *************** -->
<section class="d-flex flex-column justify-center mt-15 pa-5 pt-md-0" style="position: relative">
  <div v-if="!img" class="pt-5">
    <v-btn  icon @click="dialog = true" style="color: #0a215c; position: absolute; top: 0; right: 0;">
    <v-icon>mdi-information-outline</v-icon>
  </v-btn>
    <h4 class="subtitle-custom text-center">
      Per ottenere una maggiore attendibilità dei risultati ti chiediamo di inquadrare l'intero prodotto all'interno
      dell'area segnata
    </h4>
    <section style="position: relative;" class="ma-0 pa-0 mt-10">
      <vue-web-cam ref="webcam" :device-id="deviceId" :resolution="resolution" screenshot-format="image/jpeg"
        @started="onStarted" @stopped="onStopped" @error="onError" @cameras="onCameras"
        @camera-change="onCameraChange" />

      <div
        style="position: absolute; width: 100%; height: 97%;top: 0; border: 3px dashed #0a215c; border-radius: 50%;">

      </div>
    </section>
  </div>
  <div v-if="img && !imgError">
    <section>
      <p>
        Verifica che i codici riportati sul prodotto siano correttamente leggibili e premi su "Continua" per
        visualizzare i risultati.
      </p>
      <section style="position: relative;">
        <img :src="img" class="img-preview mt-10" />
        <section style="top: 5%; right: -1%; position: absolute">
          <v-btn class="mx-2" fab dark small color="primary" @click="clear">
            <v-icon dark>
              mdi-delete
            </v-icon>
          </v-btn>
        </section>

      </section>

    </section>
  </div>
  <div v-if="img && imgError">
    <section class="imgError d-flex justify-center align-center">
      <div>
      <v-alert
      text
      prominent
      type="error"
      icon="mdi-alert-circle-outline"
    >
      Non siamo riusciti ad ottenere un'immagine di buona qualità per la lettura dei codici, ti preghiamo di riprovare o di inserire manualmente i codici
    </v-alert>
    <v-btn class="mt-4" block color="primary" elevation="0" large @click="reload">
        Inquadra
      </v-btn>
      <v-btn class="mt-4" block color="primary" elevation="0" large @click="goToManually">
        Inserisci manualmente
      </v-btn>
    </div>
    </section>
  </div>
</section>

<!--  ******************   Button Footer    ********************* -->
<section style="text-align: center" class="pl-5 pr-5">
  <v-row>
    <v-col cols="12">
      <!-- <router-link to="/customize_picture"> -->
      <v-btn v-if="!img" block color="primary" elevation="0" large @click="onCapture">
        Cattura
      </v-btn>
      <!-- </router-link> -->
      <!-- <router-link  v-else to="/customize_picture"> -->
      <v-btn v-if="img && !imgError" block color="primary" elevation="0" large @click="confirm">
        Continua
      </v-btn>
      <!-- </router-link> -->
    </v-col>
  </v-row>
</section>

<!-- ******************* Start Layer ****************** -->
<v-row justify="center">
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" class="fixed-width">
    <v-card>
      <v-toolbar dark color="#FABFAD">
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon color="#0a215c" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider></v-divider>
      <section class="pa-5 mt-10">
        <h4 class="subtitle-custom text-center"> Per ottenere il miglior risultato possibile ti chiediamo di inquadrare il
          prodotto
          affinchè si ottenga una foto simile all'esempio riportato sotto </h4>
      </section>
      <section class="d-flex justify-center mt-10">
        <img src="../assets/placeholder.png" height="350" alt="">
      </section>
      <section class="pa-5 mt-10">
        <v-btn color="primary" elevation="0" block @click="dialog = false">Ho capito</v-btn>
      </section>
    </v-card>
  </v-dialog>
</v-row>

</v-container>
  </section>
  
</template>

<script>
import { WebCam } from "vue-web-cam";
import Navbar from "./NavbarComponent.vue"

export default {
  name: 'TakePictureComponent',
  components: {
    "vue-web-cam": WebCam,
    Navbar: Navbar
  },
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      resolutionObj: null,
      dialog: true,
      imgError: false,
      product: null,
    };
  },
  computed: {
    device: function () {
      return this.devices.find(n => n.deviceId === this.deviceId);
    },
    resolution() {
      return this.resolutionObj
    }
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      console.log('tail' + tail)
      if (first) {
        const length = this.devices.length
        if (length > 1) {
          this.camere = this.devices[length - 1].deviceId
          this.deviceId = this.devices[length - 1].deviceId
        } else {
          this.camera = first.deviceId;
          this.deviceId = first.deviceId;
        }
      }
    }
  },
  mounted() {
    this.resolutionObj = new Object()
    this.resolutionObj.height = 4320
    this.resolutionObj.width = 7680

    this.product = this.$route.params.idProduct
  },
  methods: {
    clear() {
      // this.img = null
      sessionStorage.clear()
      location.reload()
    },
    async onCapture() {
      this.img = this.$refs.webcam.capture()
      if (this.img.toString() != 'data:,') {
        sessionStorage.setItem('code', this.img)
      } else {
        this.imgError = true;
        // location.reload()
      }
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      console.log(cameras)
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      console.log(deviceId)
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
    async confirm() {
      this.$router.push('/traceability_result/' + this.product)
    },
    reload() {
      location.reload();
    },
    goToManually() {
      this.$router.push('/insert_code/' + this.product)
    }
  }
}
</script>
<style scoped>
.v-application .primary {
  background-color: #0a215c !important;
  border-color: #0a215c !important;
}

.imgError {
  min-height: 75vh;
  width: 100% !important;
}

.v-application a {
  color: #0a215c;
}

.v-application .primary--text {
  color: #0a215c !important;
  caret-color: #0a215c !important;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.img-preview {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.container {
  max-height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 580px) {
  video {
    object-fit: cover;
    width: 100%;
    height: 39vh;
    position: relative;
    top: 0;
    left: 0;
  }

  .img-preview {
    object-fit: cover;
    width: 100%;
    height: 39vh;
    position: relative;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 590px) {

.fixed-width {
  padding-left: 25% !important;
  padding-right: 25% !important;
}

video {
    object-fit: cover;
    width: 100%;
    height: 30vh;
    position: relative;
    top: 0;
    left: 0;
  }
}
</style>
