<template>
  <insert-code />
</template>

<script>
import InsertCode from '../components/InsertCodeComponent.vue'
export default {
  title: 'Rio Mare',
  components : { InsertCode }
}
</script>

<style>

</style>