<template>
  <section>
       <!-- *********** Logo ************** -->
       <Navbar position="emailSended"></Navbar>

    <section style="height: 75vh" class="d-flex justify-center align-center">
    <section class="pa-10 text-center text-custom">
      <h1>Email inviata con successo!</h1>
      <h2 class="mt-10">
        Entro 48h riceverai le informazioni che hai richiesto
      </h2>
    </section>
  </section>
  </section>
</template>

<script>
import Navbar from "./NavbarComponent.vue"
export default {
  components: {
      Navbar: Navbar
    },
   data: () => ({
      checkbox: false,
      email: null,
      prod_res: null,
      loaded: false,
      note: null,
      itemDetais: null,
      rules: [
        value => !!value || 'Campo obbligatorio.',
        v => /.+@.+/.test(v) || 'Inserisci un indirizzo mail valido'
      ],
    }),
    computed: {
      disclaimer () {
        return 'Bolton Food S.p.A. con sede legale in Cermenate (CO), Via Luigi Einaudi 18/22, in qualità di Titolare del trattamento tratterà i Suoi dati personali (indirizzo e-mail) esclusivamente al fine di gestire le Sua richiesta di informazioni relative alla tracciabilità del tonno dei nostri prodotti. Il conferimento dei dati è necessario e il loro eventuale mancato conferimento comporta il mancato invio del form e, dunque, l’impossibilità di ricevere e soddisfare la Sua richiesta. Un parziale o errato rilascio dei dati, potrà comportare l’impossibilità di evadere pienamente o correttamente la Sua richiesta. Il trattamento dei dati in relazione alla compilazione del presente form ha come base giuridica il Suo consenso. I dati personali da Lei forniti saranno trattati con strumenti informatici nel rispetto di adeguate misure tecniche e organizzative di sicurezza idonee a prevenire la perdita dei dati, usi illeciti o non corretti e accessi non autorizzati. I Suoi dati verranno conservati per un periodo di 12 mesi dalla raccolta. Ai sensi del Capo III del GDPR, Lei ha sempre diritto a richiedere al Titolare l’accesso ai Suoi dati, la rettifica o la cancellazione degli stessi, la limitazione del trattamento o la possibilità di opporsi al trattamento, di richiedere la portabilità dei dati, di revocare il consenso al trattamento facendo valere questi e gli altri diritti previsti dal GDPR tramite semplice comunicazione al Titolare. Per richiedere informazioni sul trattamento dei dati od esercitare i diritti sui Suoi dati, La preghiamo di contattarci all’indirizzo privacy@boltonfood.com. È possibile contattare Il DPO inviando una e-mail all’indirizzo dpo@boltongroup.it.'
      },
    },
}
</script>

<style scoped>
 .v-application .primary {
    background-color: #0a215c !important;
    border-color: #0a215c !important;
}

.v-application a {
    color: #0a215c;
}

.v-application .primary--text {
    color: #0a215c !important;
    caret-color: #0a215c !important;
}

.text-error {
  color: red;
}

.text-danger {
  color: orange;
}

.text-success {
  color: green;
}

a {
    text-decoration: none;
  }

h1 {
  font-size: 1.9rem;
  color: #0a215c;
}

.text-custom {
  color: #0a215c !important;
  font-family: "Pluto" !important;
}

</style>
