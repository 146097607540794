<template>
  <traceability-component />
</template>

<script>
import TraceabilityComponent from '../components/TraceabilityComponent.vue'
export default {
  title: 'Rio Mare',
  components: { TraceabilityComponent }

}
</script>

<style>

</style>