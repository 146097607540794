<template>
  <v-container>
    
    <!-- *********** Logo ************** -->
   <Navbar position="insertCode"></Navbar>

<section v-if="loaded && (!resultOK && !resultKO)">
    <!-- *************  Body    *************** -->
    <section class="pr-5 pl-5 mt-15 mb-5">
      <div style="width: 100%" class="d-flex justify-center">
      <img src="../assets/placeholder.png" width="150" alt="">
      </div>
      <h3 class="mt-4 subtitle-custom">
        Inserisci di seguito le informazioni che leggi sulla confezione
      </h3>
    </section>

    <v-form ref="form"
        v-model="valid">
    <section class="pl-5 pr-5">
     <v-text-field
            label="Codice Lotto"
            hint="Es. L19276"
            persistent-hint
            outlined
            v-model="codiceLotto"
          ></v-text-field>

        <v-text-field
            label="Linea Produzione"
            :hint="line"
            persistent-hint
            outlined
            v-model="lineaProduzione"
          ></v-text-field>

       <v-text-field
            label="Ora Produzione"
            hint="Es. 12:32"
            persistent-hint
            :rules="[rules.hours]"
            outlined
            v-model="oraProduzione"
          ></v-text-field>

    </section>

    <!--  ******************   Button Footer    ********************* -->
    <section class="pl-5 pr-5 pt-15 mt-15">
      <v-row>
      <v-col cols="12">
        <v-btn
          block
          color="primary"
          elevation="0"
          large
          @click="verifyTraceability"
          :disabled="!codiceLotto || !lineaProduzione || !oraProduzione || !valid"
        >
        Scopri la tracciabilità
        </v-btn>
      </v-col>
    </v-row>
    </section>
  </v-form>
  </section>

     <section v-if="!loaded" style="height: 75vh" class="d-flex justify-center align-center">
      <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>

    </section>

      <!-- *********************  Traceability result OK ***************** -->
    <section v-if="resultOK && loaded" style="height: 75vh" class="d-flex justify-center align-center">
      <v-card
         color="success"
         elevation="2"
         class="mx-auto pa-5 mt-10"
         style="position: relative"
      >
        <div style="position: absolute; top: 10px; right: 10px;">
          <v-btn
              icon
              color="white"
               @click="closeAlert"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <v-card-title class="mt-3">
          <p  class="text-center" style="white-space:pre-wrap; word-break:break-word;color:white !important">
            La tracciabilità del prodotto selezionato è stata verificata con successo
          </p> 
          <p class="text-center mt-5" style="width: 100%; color: #fff;">
            <a :href="url" style="color: #fff !important"><v-btn color="primary" elevation="4" class="pa-2">Scopri la tracciabilità</v-btn></a>
          </p>
            
        </v-card-title>
      </v-card>

    </section>

    <!-- *********************  Traceability result KO ***************** -->
    <section v-if="resultKO && loaded" style="height: 75vh" class="d-flex justify-center align-center">
      <v-card
         color="error"
         elevation="2"
         class="mx-auto pa-5 mt-10"
         style="position: relative"
      >
        <div style="position: absolute; top: 10px; right: 10px;">
          <v-btn
              icon
              color="white"
              @click="closeAlert"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <v-card-title class="mt-3">
          <p  class="text-center" style="white-space:pre-wrap; word-break:break-word;color:white !important">
            La tracciabilità del prodotto selezionato non è stata verificata. 
            Ti chiediamo di verificare i dati scansionati e/o inseriti e riprovare 
          </p> 
            
        </v-card-title>
      </v-card>
    </section>
    
  </v-container>
</template>

<script>
import axios from 'axios'
import Navbar from "./NavbarComponent.vue"
  export default {
    name: 'InsertCodeComponent',
    components: {
      Navbar: Navbar
    },
    data () {
      return {
         loaded: false,
         valid: true,
         codiceLotto: '',
         lineaProduzione: '',
         oraProduzione: '',
         prod_res: null,
         resultOK: false,
         resultKO: false,
         errorCount: 0,
         previousCode: null,
         previousProduction: null,
         previousTime: null,
         url: '',
         rules: {
          required: value => !!value || 'Il campo è obbligatorio.',
          hours: value => {
            const pattern = /^([01]?[0-9]|2[0-3])(:([0-5][0-9]))$/
            return pattern.test(value) || "Il formato dell'orario inserito non è valido. Il formato corretto è HH:mm"
          },
        }
      }
    },
    computed: {
      line () {
        if ( this.prod_res.data.weight == "80g") {
          return "Es. K3"
        } else {
          return "Es. B"
        }
      }
    },
     async mounted () {
       let codeSession = this.$route.params.idProduct
        this.prod_res = await axios.get(process.env.VUE_APP_MIDDLEWARE + '/products/' + codeSession)
        console.log(this.prod_res)
        this.loaded = true;
     },
     methods: {
         verifyTraceability () {
        this.loaded = false

           let errorFinds = sessionStorage.getItem('errorCount')
            if (errorFinds) {
              this.errorCount = errorFinds
            } else {
              this.errorCount = 0
              sessionStorage.setItem('errorCount', 0)
            }
            let previousCode = sessionStorage.getItem('previousCode')
            let previousProduction = sessionStorage.getItem('previousProduction')
            let previousTime = sessionStorage.getItem('previousTime')

            if (previousCode) {
               if (previousCode.toUpperCase() != this.codiceLotto.toUpperCase()) {
              sessionStorage.setItem('previousCode', this.codiceLotto.toUpperCase())
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
               }
            } else {
              sessionStorage.setItem('previousCode', this.codiceLotto.toUpperCase())
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            if (previousProduction) {
              if (previousProduction.toUpperCase() != this.lineaProduzione.toUpperCase()) {
              sessionStorage.setItem('previousProduction', this.lineaProduzione.toUpperCase())
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            } else {
              sessionStorage.setItem('previousProduction', this.lineaProduzione.toUpperCase())
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            if (previousTime) {
              if (previousTime != this.oraProduzione) {
              sessionStorage.setItem('previousTime', this.oraProduzione )
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            } else {
              sessionStorage.setItem('previousTime', this.oraProduzione )
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }


          axios.post(process.env.VUE_APP_MIDDLEWARE + '/traceability', {
          product_id: 1,
          description: this.prod_res.data.name,
          encodingImageId: this.prod_res.data.encodingImageId,
          weight: this.prod_res.data.weight,
          weightId: this.prod_res.data.weightId,
          lot: this.codiceLotto,
          code: this.lineaProduzione,
          production_hours: this.oraProduzione
        })
        .then( (res) => {
          const lot = res
          if (lot.data.fishLot == "-") {
            let itemDetails = new Object()
            itemDetails.description = this.prod_res.data.name
            itemDetails.lot = this.codiceLotto,
            itemDetails.code = this.lineaProduzione,
            itemDetails.encodingImageId = this.prod_res.data.encodingImageId
            itemDetails.production_hours = this.oraProduzione
            itemDetails.weight = this.prod_res.data.weight,
            itemDetails.weightId = this.prod_res.data.weightId,
            itemDetails.note = lot.data.lotToHiddenNote

            sessionStorage.setItem('ItemDetails', JSON.stringify(itemDetails))

            this.$router.push('/email')
          } else {
            this.loaded = true
            this.resultOK = true
            this.resultKO = false
            let url = "https://tracciabilita.riomare.it/?"
            url += "encId=" + this.prod_res.data.encodingImageId
            url += "&tuna=" + lot.data.mpaDescr
            url += "&fishMet=" + lot.data.fishingGear
            url += "&fishMetDesc=" + lot.data.fishingGearDescr
            url += "&fao=" + lot.data.faoFishingArea
            url += "&ocean=" + lot.data.oceanCaptureDescr
            url += "&vessel=" + lot.data.vessel
            url += "&vesselDesc=" + lot.data.vesselFlagDescr
            url += "&port=" + lot.data.portOfLanding
            url += "&portDesc=" + lot.data.portOfLandingFlagDescr
            url += "&certStatus=" + lot.data.vesselCertificationStatus
            url += "&certFishery=" + lot.data.vesselCertificationFishery
            url += "&producer=" + lot.data.producerSapCode 
            this.url = url;
            setTimeout(() => {
              window.open(url, "_blank")
            }, 100);
          }
          
        } ) 
        .catch( err => {
          if (err.response.status == 404) {
            this.loaded = true
            this.resultKO = true
            this.resultOK = false
            this.errorCount++
            sessionStorage.setItem('errorCount', this.errorCount)  
            if (this.errorCount > 1 ) {
               let itemDetails = new Object()
                itemDetails.description = this.prod_res.data.name
                itemDetails.lot = this.codiceLotto,
                itemDetails.code = this.lineaProduzione,
                itemDetails.production_hours = this.oraProduzione
                itemDetails.encodingImageId = this.prod_res.encodingImageId
                itemDetails.weight = this.prod_res.data.weight,
                itemDetails.weightId = this.prod_res.data.weightId,
                itemDetails.note = '-'

                sessionStorage.setItem('ItemDetails', JSON.stringify(itemDetails))

              this.$router.push('/email')
            }
          }
          
        })
      },
      closeAlert () {
        this.resultKO = false
        this.resultOK = false
      }
     }
  }
</script>
<style scoped>
  .v-application .primary {
    background-color: #0a215c !important;
    border-color: #0a215c !important;
}

.v-application a {
    color: #0a215c;
}

.v-application .primary--text {
    color: #0a215c !important;
    caret-color: #0a215c !important;
}

</style>
