<template>
  <take-picture />
</template>

<script>
import TakePicture from '../components/TakePicture.vue'
export default {
  title: 'Rio Mare',
  components: {
      TakePicture
    }

}
</script>

<style>
.v-application .primary {
    background-color: #0a215c !important;
    border-color: #0a215c !important;
}

.v-application a {
    color: #0a215c;
}

.v-application .primary--text {
    color: #0a215c !important;
    caret-color: #0a215c !important;
}

</style>