<template>
  <v-container>
    
    <!-- *********** Header ************** -->
    <Navbar position="traceability"></Navbar>

    <!-- *************  Body    *************** -->
    <section v-if="loaded && (!resultOK && !resultKO)">
    <section class="pr-5 pl-5 mt-15">
      <v-alert
        v-if="error"
        color="red"
        dismissible
        type="error"
      >
    Si è verificato un errore durante la lettura. Ti chiediamo di riprovare l'operazione oppure di inserire manualmente i codici riportati sul prodotto nell'apposito spazio sotto</v-alert>
      <p>
        Di seguito trovi il risultato della lettura da parte del nostro servizio intelligente.
      </p>
      <h3 class="mt-2 subtitle-custom">
        Confidenza dell'elaborazione:
      </h3>
      <h1 class="text-center" :class="{'text-success': confTot >= 90, 'text-success-medium': confTot < 90 && confTot >= 75, 'text-success-low': confTot < 75 && confTot >= 60, 'text-danger-high': confTot < 60 && confTot >= 50, 'text-danger': confTot < 50 }" style="font-size: 28px">
        <b>
          {{ confTot }}%
        </b>
      </h1>
      <p>
        Ti chiediamo di confermare la lettura fatta, e modificarla se necessario.
      </p>
    </section>

    <section class="pl-5 pr-5">
      <v-row>
        <v-col cols="8">
          <h4 class="subtitle-custom"><b>Dati Rilevati </b></h4>
        </v-col>
        <v-col cols="4">
         <h4 class="subtitle-custom"><b>Confidenza</b> </h4>
        </v-col>
      </v-row>
      <v-row class="pl-3">
        <v-col cols="8" class="pa-0">
          <v-text-field
            label="Codice Lotto"
            placeholder="Es. L19276"
            hint="Es. L19276"
            persistent-hint
            :rules="[rules.required]"
            outlined
            v-model="codiceLotto"
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="pa-0 d-flex justify-center mt-2" :class="{'text-success': codlottoConf >= 90, 'text-success-medium': codlottoConf < 90 && codlottoConf >= 75, 'text-success-low': codlottoConf < 75 && codlottoConf >= 60, 'text-danger-high': codlottoConf < 60 && codlottoConf >= 50, 'text-danger': codlottoConf < 50 }">
          <h2><b>{{ codlottoConf }}%</b></h2>
        </v-col>
      </v-row>
      <v-row class="pl-3">
        <v-col cols="8" class="pa-0">
          <v-text-field
            label="Linea Produzione"
            :placeholder="line"
            :hint="line"
            persistent-hint
            v-model="lineaProduzione"
            :rules="[rules.required]"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="pa-0 d-flex justify-center mt-2" :class="{'text-success': lineaProdConf >= 90, 'text-success-medium': lineaProdConf < 90 && lineaProdConf >= 75, 'text-success-low': lineaProdConf < 75 && lineaProdConf >= 60, 'text-danger-high': lineaProdConf < 60 && lineaProdConf >= 50, 'text-danger': lineaProdConf < 50 }">
          <h2><b> {{ lineaProdConf }}%</b></h2>
        </v-col>
      </v-row>
          <v-row class="pl-3">
            <v-col cols="8" class="pa-0">
              <v-text-field
            label="Ora Produzione"
            placeholder="Es. 12:32"
            hint="Es. 12:32"
            persistent-hint
            v-model="oraProduzione"
            :rules="[rules.required, rules.hours]"
            outlined
          ></v-text-field>
            </v-col>
            <v-col cols="4" class="pa-0 d-flex justify-center mt-2" :class="{'text-success': oraProdConf >= 90, 'text-success-medium': oraProdConf < 90 && oraProdConf >= 75, 'text-success-low': oraProdConf < 75 && oraProdConf >= 60, 'text-danger-high': oraProdConf < 60 && oraProdConf >= 50, 'text-danger': oraProdConf < 50 }">
              <h2><b>{{ oraProdConf }}%</b></h2>
            </v-col>
          </v-row>
    </section>

    <!--  ******************   Button Footer    ********************* -->
    <section style="text-align: center" class="pl-5 pr-5 pt-15">
      <v-row>
        <v-col cols="12">
          <v-checkbox v-if="confTot < 50"
            v-model="checkbox"
            label="Ho verificato i dati e desidero scoprire la tracciabilità"
            ></v-checkbox>
        </v-col>
      <v-col cols="12">
        <v-btn
          block
          color="primary"
          elevation="0"
          large
          @click="verifyTraceability"
          :disabled="!codiceLotto || !oraProduzione || !lineaProduzione || (confTot < 50 && !checkbox ) "
        >
        Scopri la tracciabilità
        </v-btn>
      </v-col>
    </v-row>
    </section>
    </section>

    <section v-if="!loaded" style="height: 75vh; position: relative;" class="d-flex justify-center align-center">
      <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    >
    </v-progress-circular>
    <p style="position: absolute; bottom: 10%" class="mt-3 text-center" v-if="slowOperation">
    L'elaborazione sta richiedendo più tempo del previsto <br>
    Ti preghiamo di attendere </p>
   
    </section>

    <!-- *********************  Traceability result OK ***************** -->
    <section v-if="resultOK && loaded" style="height: 75vh" class="d-flex justify-center align-center">
      <v-card
         color="success"
         elevation="2"
         class="mx-auto pa-5 mt-10"
         style="position: relative"
      >
        <div style="position: absolute; top: 10px; right: 10px;">
          <v-btn
              icon
              color="white"
               @click="closeAlert"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <v-card-title class="mt-3">
          <p  class="text-center" style="white-space:pre-wrap; word-break:break-word;color:white !important">
            La tracciabilità del prodotto selezionato è stata verificata con successo
          </p> 
          <p class="text-center" style="width: 100%; color: #fff;">
            <a :href="url" style="color: #fff !important"> <v-btn color="primary" elevation="4" class="pa-2">Scopri la tracciabilità</v-btn></a>
          </p>
            
        </v-card-title>
      </v-card>

    </section>

    <!-- *********************  Traceability result KO ***************** -->
    <section v-if="resultKO && loaded" style="height: 75vh" class="d-flex justify-center align-center">
      <v-card
         color="error"
         elevation="2"
         class="mx-auto pa-5 mt-10"
         style="position: relative"
      >
        <div style="position: absolute; top: 10px; right: 10px;">
          <v-btn
              icon
              color="white"
              @click="closeAlert"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <v-card-title class="mt-3">
          <p  class="text-center" style="white-space:pre-wrap; word-break:break-word;color:white !important">
            La tracciabilità del prodotto selezionato non è stata verificata. 
            Ti chiediamo di verificare i dati scansionati e/o inseriti e riprovare 
          </p> 
            
        </v-card-title>
      </v-card>
    </section>

    <!-- percentage  -->
    <div class="text-center">
    <v-dialog
      v-model="percentage"
      width="500"
    >
      <v-card class="percentageStyle">
        <v-card-title class="text-h5">
          <b> Attenzione </b>
        </v-card-title>

        <v-card-text style="color: #fff;">
         <b> La confidenza dell'elaborazione non è ottimale. <br>
          Ti chiediamo di verificare la correttezza dei dati e, nel caso, reinserirli manualmente </b>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="percentage = false"
          >
            Ho capito
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
    
  </v-container>
</template>

<script>
// import axios from 'axios'
import Navbar from "./NavbarComponent.vue"
  export default {
    name: 'TraceabilityComponent',
    components: {
      Navbar: Navbar
    },
    data () {
      return {
        error: false,
        file: null,
        loaded: false,
        codiceLottoConfidenza: 0,
        codiceLotto: '',
        lineaProduzioneConfidenza: 0,
        lineaProduzione: '',
        oraProduzioneConfidenza: 0,
        oraProduzione: '',
        prod_res: null,
        code: null,
        resultOK: false,
        resultKO: false,
         errorCount: 0,
         previousCode: null,
         previousProduction: null,
         previousTime: null,
         checkbox: false,
         idProd: null,
         slowOperation: null,
         percentage: false,
         url: '',
         rules: {
          required: v => !!v || 'Inserisci manualmente il valore',
          hours: value => {
            const pattern = /^([01]?[0-9]|2[0-3])(:([0-5][0-9]))$/
            return pattern.test(value) || "Il formato dell'orario inserito non è valido. Il formato corretto è HH:mm"
          }, 
         } 
          
        
      }
    },
    async mounted () {
      this.error = false;
      this.slowOperation = false
      try {
        setTimeout(() => {
          this.slowOperation = true
        }, 10000);
       // let codeSession = this.$route.params.idProduct
       // this.prod_res = await axios.get(process.env.VUE_APP_MIDDLEWARE + '/products/' + codeSession)


       // const prod_res = await axios.get(process.env.VUE_APP_MIDDLEWARE + '/products/' + codeSession)
       // this.prod_res = prod_res
       // const code = this.prod_res.data.productList
       // const productDetails = this.prod_res.data.name + " " + this.prod_res.data.weight
       // let originalImage = sessionStorage.getItem('code')

       /*
        axios.post(process.env.VUE_APP_MIDDLEWARE + '/log', {})
        .then(res => {
          this.idProd = res.data.id
        })
        */

        /*
        axios.post(process.env.VUE_APP_MIDDLEWARE + '/imageProcessing', {
          originalImage: originalImage,
          code: code,
          productDetails: productDetails,
          weight: prod_res.data.weight
        })
        .then((res) => {
          let result = res
          this.codiceLottoConfidenza = (result.data.lotCodeConfidence && result.data.lotCodeConfidence != "null" ) ? result.data.lotCodeConfidence : "",
          this.codiceLotto = (result.data.lotCode && result.data.lotCode != "null" ) ? result.data.lotCode : "",
          this.lineaProduzioneConfidenza = result.data.productionLineConfidence ? result.data.productionLineConfidence : 0,
          this.lineaProduzione = (result.data.productionLine && result.data.productionLine !== "null") ? result.data.productionLine : "",
          this.oraProduzioneConfidenza = result.data.productionHourConfidence ? result.data.productionHourConfidence : 0,
          this.oraProduzione = (result.data.productionHour && result.data.productionHour != "null") ? result.data.productionHour : ""

          axios.patch(process.env.VUE_APP_MIDDLEWARE + '/log/' + this.idProd, {
            lotCode:  this.codiceLotto,
            lotCodeConfidence:  this.codiceLottoConfidenza,
            productionTime: this.lineaProduzione,
            productionTimeConfidence: this.lineaProduzioneConfidenza,
            time: this.oraProduzione,
            timeConfidence: this.oraProduzioneConfidenza
          })
          this.slowOperation = false
          this.loaded = true
        })
        .catch((error) => {
          console.log(error)
          this.loaded = true
          this.error = true;
          this.slowOperation = false;
        })
          */

          this.codiceLottoConfidenza = 99,
          this.codiceLotto = "L23255",
          this.lineaProduzioneConfidenza = 99,
          this.lineaProduzione = "F",
          this.oraProduzioneConfidenza = 99,
          this.oraProduzione = "13:41"

          setTimeout(() => {
          this.slowOperation = false
          this.loaded = true
          }, 2000);

      } catch (e) {
        console.error(e)
        this.loaded = true;
        this.error = true;
      }
    },
    computed: {
      line () {
        // if ( this.prod_res.data.weight == "80g") {
        //   return "Es. K3"
        // } else {
        //   return "Es. B"
        // }
        return "Es. B"
      },
     codlottoConf () {
        return Math.trunc((this.codiceLottoConfidenza))
      },
      lineaProdConf () {
        return Math.trunc(this.lineaProduzioneConfidenza)
      },
      oraProdConf () {
        return Math.trunc(this.oraProduzioneConfidenza)
      },
      confTot () {
        let sum = ((this.lineaProduzioneConfidenza * 1) + (this.codiceLottoConfidenza * 1 )+ (this.oraProduzioneConfidenza * 1))
        if (sum > 0) {
          let tot = Math.round((sum / 3))
          if (tot > 100 ) {
            return 100
          } else {
            if (tot < 95) {
              this.activatePercentage()
            } 
            return tot } 
        } else {
          return 0
        }
      }
    },
    methods: {
      verifyTraceability () {
        this.loaded = false

        /*

         let errorFinds = sessionStorage.getItem('errorCount')
            if (errorFinds) {
              this.errorCount = errorFinds
            } else {
              this.errorCount = 0
              sessionStorage.setItem('errorCount', 0)
            }
            let previousCode = sessionStorage.getItem('previousCode')
            let previousProduction = sessionStorage.getItem('previousProduction')
            let previousTime = sessionStorage.getItem('previousTime')

            if (previousCode) {
               if (previousCode.toUpperCase() != this.codiceLotto.toUpperCase()) {
              sessionStorage.setItem('previousCode', this.codiceLotto.toUpperCase())
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
               }
            } else {
              sessionStorage.setItem('previousCode', this.codiceLotto.toUpperCase())
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            if (previousProduction) {
              if (previousProduction.toUpperCase() != this.lineaProduzione.toUpperCase()) {
              sessionStorage.setItem('previousProduction', this.lineaProduzione.toUpperCase())
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            } else {
              sessionStorage.setItem('previousProduction', this.lineaProduzione.toUpperCase())
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            if (previousTime) {
              if (previousTime != this.oraProduzione) {
              sessionStorage.setItem('previousTime', this.oraProduzione )
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            } else {
              sessionStorage.setItem('previousTime', this.oraProduzione )
              sessionStorage.setItem('errorCount', 0)
              this.errorCount = 0
            }
            
          axios.post(process.env.VUE_APP_MIDDLEWARE + '/traceability', {
          product_id: 1,
          description: this.prod_res.data.name,
          encodingImageId: this.prod_res.data.encodingImageId,
          weight: this.prod_res.data.weight,
          weightId: this.prod_res.data.weightId,
          lot: this.codiceLotto,
          code: this.lineaProduzione,
          production_hours: this.oraProduzione
        })
        .then( (res) => {
          const lot = res
          if (lot.data.fishLot == "-") {
            let itemDetails = new Object()
            itemDetails.description = this.prod_res.data.name
            itemDetails.lot = this.codiceLotto,
            itemDetails.code = this.lineaProduzione,
            itemDetails.production_hours = this.oraProduzione
            itemDetails.encodingImageId = this.prod_res.encodingImageId
            itemDetails.weight = this.prod_res.data.weight,
            itemDetails.weightId = this.prod_res.data.weightId,
            itemDetails.note = lot.data.lotToHiddenNote

            sessionStorage.setItem('ItemDetails', JSON.stringify(itemDetails))

            this.$router.push('/email')
          } else {
            this.loaded = true
            this.resultOK = true
            this.resultKO = false
            let url = "https://tracciabilita.riomare.it/?"
            url += "encId=" + this.prod_res.data.encodingImageId
            url += "&tuna=" + lot.data.mpaDescr
            url += "&fishMet=" + lot.data.fishingGear
            url += "&fishMetDesc=" + lot.data.fishingGearDescr
            url += "&fao=" + lot.data.faoFishingArea
            url += "&ocean=" + lot.data.oceanCaptureDescr
            url += "&vessel=" + lot.data.vessel
            url += "&vesselDesc=" + lot.data.vesselFlagDescr
            url += "&port=" + lot.data.portOfLanding
            url += "&portDesc=" + lot.data.portOfLandingFlagDescr
            url += "&certStatus=" + lot.data.vesselCertificationStatus
            url += "&certFishery=" + lot.data.vesselCertificationFishery
            url += "&producer=" + lot.data.producerSapCode 
            this.url = url;
            setTimeout(() => {
              window.open(url, "_blank")
            }, 100);
          }
        } ) 
        .catch( (err) => {
           if (err.response.status == 404) {
            this.loaded = true
            this.resultKO = true
            this.resultOK = false
            this.errorCount++
            sessionStorage.setItem('errorCount', this.errorCount)  
            if (this.errorCount > 1 ) {
               let itemDetails = new Object()
                itemDetails.description = this.prod_res.data.name
                itemDetails.lot = this.codiceLotto,
                itemDetails.code = this.lineaProduzione,
                itemDetails.production_hours = this.oraProduzione
                itemDetails.encodingImageId = this.prod_res.encodingImageId
                itemDetails.weight = this.prod_res.data.weight,
                itemDetails.weightId = this.prod_res.data.weightId,
                itemDetails.note = '-'

                sessionStorage.setItem('ItemDetails', JSON.stringify(itemDetails))

              this.$router.push('/email')
            }
          }
        })
          */

          this.resultOK = true
          this.resultKO = false
          window.open("https://tracciabilita.riomare.it/report", "_blank")
          this.loaded = true
      },
      closeAlert () {
        this.resultKO = false
        this.resultOK = false
      },
      activatePercentage () {
        this.percentage = true;
      }
    }

    
  }
</script>
<style scoped>
  .v-application .primary {
    background-color: #0a215c !important;
    border-color: #0a215c !important;
}

.v-application a {
    color: #0a215c;
}

.v-application .primary--text {
    color: #0a215c !important;
    caret-color: #0a215c !important;
}

.text-error {
  color: red;
}

.text-danger {
  color: #FFDC41;
}

.text-success {
  color: #6BB400;
}

.text-success-medium {
  color: #95D500;
}

.text-success-low {
  color: #A7F001;
}

.text-danger-high {
  color: #DDF628;
}

a {
    text-decoration: none;
  }

.percentageStyle {
  background-color: rgba(255,171,25,0.9);
  border: solid 2px #ffe121 ;
  color: #ffe121;
}
</style>
