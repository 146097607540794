<template>
    <!-- *********** Header ************** -->
    <section class="footer footer-position pa-10">
        <v-row>
            <v-col class="text-center" cols="12">
                <a href="https://www.facebook.com/RioMareItalia/" target="_blank"><font-awesome-icon style="font-size: 24px" class="mr-2" icon="fa-brands fa-facebook" /></a>
                <a href="https://www.instagram.com/riomare/" target="_blank"><font-awesome-icon style="font-size: 24px" class="mr-2" icon="fa-brands fa-instagram" /></a>
                <a href="https://www.youtube.com/user/RioMareOfficial" target="_blank"><font-awesome-icon style="font-size: 24px" class="mr-2" icon="fa-brands fa-youtube" /></a>
            </v-col>
            <v-col class="text-center" cols="12">
               <h6 class="subtitle-custom" style="color: #fff;"> All rights reserved.
                © 2023 BOLTON FOOD S.p.A. <br />
                P. IVA 00197980139 </h6>
            </v-col>
        </v-row>
    </section>
</template>

<script>
export default {
    name: 'FooterComponent',
}
</script>
<style scoped>
.footer {
    background-color: #0a215c !important;
    color: #fff !important;
}

a {
    color:#fff !important
}
</style>
