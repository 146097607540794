import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import { library } from "@fortawesome/fontawesome-svg-core";

// import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";


library.add(faFacebook);
library.add(faTwitter);
library.add(faYoutube);
library.add(faInstagram);
library.add(faLinkedin);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Vuetify);


export default new Vuetify({
});
