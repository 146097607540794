<template>
    <!-- *********** Header ************** -->
    <section>
        <v-app-bar class="app-bar elevation-0" elevation="4" color="#FABFAD">
            <img height="30" src="../assets/rio-mare-logo.svg">
            <v-spacer></v-spacer>
            <router-link style="text-decoration: none !important;
                    font-family: 'PlutoLight' !important;
                    color: #0a215c !important;" class="mr-3" to="/">
                <h5><b> HOME </b> </h5>
            </router-link>
            <section v-if="position == 'traceability'">
                <router-link style="text-decoration: none !important;
                    font-family: 'PlutoLight' !important;
                    color: #0a215c !important;" :to="'/scan_code' + codeSession ">
                    <!-- <v-btn icon style="color: #0a215c">
                    <v-icon>mdi-home</v-icon>
                </v-btn> -->
                    <h5> INQUADRA </h5>
                </router-link>
            </section>
        </v-app-bar>
    </section>
</template>

<script>
export default {
    name: 'NavbarComponent',
    props: {
        position: String,
    },
    data() {
        return {
            codeSession: null
        }
    },
    computed: {
        infoDisplayed() {
            if (this.position == "picture") {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
        this.codeSession = this.$route.params.idProduct
    },
}
</script>
<style scoped>
.v-appication a {
    text-decoration: none !important;
    font-family: 'PlutoLight' !important;
    color: #0a215c !important;
}
</style>
