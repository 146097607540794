<template>
  <v-app class="padding">
    <v-main class="overflow-h" style="background-color: #fff; position: relative">
      <router-view />
      <Footer class="mt-10" />
    </v-main>

  </v-app>
</template>

<script>
import Footer from "./components/FooterComponent.vue"
export default {
  name: 'App',
  components: {
    Footer
  },
  data: () => ({
    //
  }),
};
</script>

<style>

@font-face {
  font-family: "PlutoLight";
  src: local("PlutoLight"),
   url("./fonts/PlutoLight/PlutoLight.ttf") format("truetype");
}

@font-face {
  font-family: "PlutoBold";
  src: local("PlutoBold"),
   url("./fonts/PlutoBold/PlutoBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  src: local("Lato"),
   url("./fonts/Lato/Lato-Regular.ttf") format("truetype");
}

body {
  font-family: 'Lato', sans-serif !important;
  background-color: #FCEAE5 !important;
}

.v-btn {
  border-radius: 0px !important;
}

.title-custom {
  color: #0a215c;
  font-family: 'PlutoBold', 'Open Sans', sans-serif !important;
}

.subtitle-custom {
  color: #0a215c;
  font-family: 'PlutoLight', 'Open Sans', sans-serif !important;
}

.theme--light.v-application {
    background: #FCEAE5 !important;
    color: rgba(0, 0, 0, 0.87);
}


.v-application .primary {
  background-color: #0a215c !important;
  border-color: #0a215c !important;
}

.v-application a {
  color: #0a215c;
}

.v-application .primary--text {
  color: #0a215c !important;
  caret-color: #0a215c !important;
}

.bg-custom {
  background-color: #FABFAD !important;
}

.v-btn__content {
  font-family: 'PlutoLight';
}

.text-primary {
  color: #0a215c !important;
}

p,
label {
  font-family: 'Lato' !important;
  color: #0a215c !important;
}

.app-bar {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
}

.v-list-item__title {
  overflow: initial !important;
  text-overflow: clip !important;
  white-space: initial !important;
}

.overflow-h {
  overflow: hidden !important;
}

.button-footer {
  position: fixed;
  bottom: 30px;
  width: 100%;
  left: 0;
}

/* ********* Media ************ */

@media screen and (min-width: 580px) {
  .padding {
    padding-left: 25% !important;
    padding-right: 25% !important;
  }

  .v-dialog--fullscreen {
    border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    padding-left: 25% !important;
    padding-right: 25%;
}

  .button-footer {
    position: relative;
    margin-top: 1rem;
  }

  .overflow-h {
    overflow: scroll !important;
  }
}

@media screen and (max-width: 300px) {
  .v-btn--block {
    min-width: none !important;
}

.v-btn--block {
    display: flex;
    flex: 1 0 auto;
    min-width: 50% !important;
    max-width: auto;
}
}

@media screen and (min-width: 700px) and (min-height: 1025px) {
  .footer-position {
    position: absolute;
    bottom: 0px;
  }
}
</style>
